<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onApproved(recommendation))"
      @close="handleSubmit(onDeclined(recommendation))"
      submit="Accept"
      cancel="Decline"
      :loading="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      width="520px"
      :close-button="!recommendation"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="isOpen = false" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            Review Proposal
          </span>
        </div>
      </template>
      <template v-if="recommendation">
        <template>
          <div class="border-b border-dashed border-romanSilver mb-5">
            <div class="flex justify-between items-start w-full gap-2">
              <div class="w-4/12 h-60 rounded-md text-blueCrayola font-normal">
                  <img class="h-56 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
                  <div v-else class="h-56 border rounded-md flex justify-center items-center">
                  <span style="font-size:60px">
                    {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                  </span>
                  </div>
                </div>
              <div class="w-8/12 flex flex-col justify-start items-start gap-2">
                <h4 class="font-bold text-lg leading-5 text-darkPurple">
                  {{ employeeInfo.fname }} {{ employeeInfo.lname }}
                </h4>
                <div class="flex flex-col justify-start items-center w-full gap-2">
                  <div class="flex justify-start items-center w-full">
                    <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                      Function:
                    </p>
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.function }}
                    </p>
                  </div>
                  <div class="flex justify-start items-center w-full">
                    <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                      Designation:
                    </p>
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.designation }}
                    </p>
                  </div>
                  <div class="flex justify-start items-center w-full">
                    <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                      Job Level:
                    </p>
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      <span v-if="employeeInfo.level">
                        {{ employeeInfo.level.name }}
                      </span>
                    </p>
                  </div>
                  <div class="flex justify-start items-center w-full">
                    <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                      Office Location:
                    </p>
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.location }}
                    </p>
                  </div>
                  <div class="flex justify-start items-center w-full">
                    <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                      Manager:
                    </p>
                    <template v-if="(employeeInfo.lineManager)">
                      <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                        {{ employeeInfo.lineManager.fname }} {{ employeeInfo.lineManager.lname }}
                      </p>
                    </template>
                    <p v-else>-</p>
                  </div>
                  <div class="flex justify-start items-center w-full">
                    <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                      Length of Service:
                    </p>
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      <span v-if="employeeInfo.yearsOfService">
                        {{ employeeInfo.yearsOfService }}
                      </span>
                    </p>
                  </div>
                  <div class="flex justify-start items-center w-full">
                    <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                      Last Performance Score:
                    </p>
                    <template v-if="employeeInfo.performanceScore">
                      <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                        {{ employeeInfo.performanceScore }}
                        <span class="text-red-400" v-if="employeeInfo.performanceScore <= 2">(Poor)</span>
                        <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 2 && employeeInfo.performanceScore < 4)">
                          (Average)
                        </span>
                        <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 4)">(Excellent)</span>
                      </p>
                    </template>
                    <p v-else>-</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="recommendation">
          <div class="flex flex-col justify-start items-start gap-5">
            <template>
              <div class="container flex flex-col justify-start items-start gap-1">
                <p class="font-semibold text-sm leading-5 text-darkPurple">
                  Proposal Type
                </p>
                <p class="font-normal text-sm leading-5 text-jet capitalize">
                  {{ recommendation.proposalType.split("_").join(" ") }}
                </p>
              </div>
            </template>
            <template>
              <div class="container flex flex-col justify-start items-start gap-1">
                <p class="font-semibold text-sm leading-5 text-darkPurple">
                  Recommendation
                </p>
                <p class="font-normal text-sm leading-5 text-jet capitalize">
                  {{ recommendation.adjustTo }}
                </p>
              </div>
            </template>
            <template>
              <div class="container flex flex-col justify-start items-start gap-1">
                <p class="font-semibold text-sm leading-5 text-darkPurple">
                  Justification
                </p>
                <p class="font-normal text-sm leading-5 text-jet">
                  {{ recommendation.justification }}
                </p>
              </div>
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="flex flex-col justify-center items-center gap-5 px-10 py-20">
          <Icon size="" class-name="w-32 h-32" icon-name="paygrade_Illustration" />
          <p class="font-normal text-base leading-5 text-jet text-center">
            There is no proposal for this employee presently. Check back later
          </p>
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import RightSideBar from "@/components/RightSideBar";

  export default {
    components: {
      ValidationObserver,
      BackButton,
      RightSideBar,
    },
    data(){
      return {
        isLoading: true,
        employeeInfo: {},
        recommendation: {},
        isOpen: false,
      }
    },
    methods: {
      toggle({ userId, paygradeId, recommendation }){
        this.getEmployeeCompensationSummary({ userId, paygradeId })
        this.recommendation = recommendation
        this.isOpen = true
      },
      getEmployeeCompensationSummary(payload){
        this.isLoading = true
        this.$_getEmployeeCompensationSummary(payload).then(({ data }) => {
          this.employeeInfo = {
            ...data.employee,
            ...data.employee.paygrade,
            ...data.employee.userDetails,
          }
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      },
      onApproved($event){
        this.submitProposal({ id: $event.id, status: 'approved' })
      },
      onDeclined($event){
        if($event){
          this.submitProposal({ id: $event.id, status: 'disapproved' })
        } else this.isOpen = false
      },
      submitProposal({ id, status }){
        this.isLoading = true
        this.$_updateCompensationProposal({ id, status })
        .then(({ data }) => {
          this.$emit('success', data.success)
          this.$toasted.success(data.message, {duration: 3000})
          this.isLoading = false
          this.isOpen = false
        }).catch(({ response }) => {
          this.$toasted.error(response.message, {duration: 3000})
          this.isLoading = false
        })
      },
    },
  }
</script>

<style>
  .rightSidebar{
    width: 540px !important;
  }
</style>

